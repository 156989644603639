const getAllBrokerPasswordResetInputsValid: () => boolean = () => {
  try {
    const passwordInput: any = document.getElementById('password');
    const passwordConfirmInput: any = document.getElementById('passwordConfirmed');
    const isValidPassword: boolean = getIsValidPassword(passwordInput?.value || '');
    const isValidPasswordConfirmed: boolean =
      getIsValidPassword(passwordConfirmInput?.value || '') && passwordInput?.value === passwordConfirmInput?.value;
    const allValid = isValidPasswordConfirmed && isValidPassword;

    if (!isValidPasswordConfirmed) setPasswordInputError('passwordConfirmed');
    if (!isValidPassword) setPasswordInputError('password');

    if (allValid) return true;

    return false;
  } catch (error) {
    console.log(error);

    return false;
  }
};

const migrateBrokerPasswordResetValuesToNewFormAndReturnSuccess: () => boolean = () => {
  try {
    const passwordID: string = 'password';
    const passwordConfirmedID: string = 'passwordConfirmed';

    const new_passwordID: string = 'new_password';
    const new_passwordConfirmedID: string = 'new_passwordConfirmed';

    const passwordMigrated: boolean = relocatePasswordInputValueByIdToNewInputByIdAndReturnSuccess(
      passwordID,
      new_passwordID
    );
    const passwordConfirmedMigrated: boolean = relocatePasswordInputValueByIdToNewInputByIdAndReturnSuccess(
      passwordConfirmedID,
      new_passwordConfirmedID
    );
    const allMigrated: boolean = passwordConfirmedMigrated && passwordMigrated;

    if (allMigrated) return true;

    return false;
  } catch (error) {
    console.log(error);

    return false;
  }
};

const handleBrokerPasswordResetSubmission: (event: any) => void = (event: any) => {
  preventDefaultFunction(event);

  try {
    const allBrokerPasswordResetInputsValid: boolean = getAllBrokerPasswordResetInputsValid();
    const loadingContainer: any = document.getElementById('loading-container');

    loadingContainer.classList.add('active');

    if (!allBrokerPasswordResetInputsValid) {
      handleBrokerPasswordResetSubmissionError();
      return;
    }

    const allMigrated: boolean = migrateBrokerPasswordResetValuesToNewFormAndReturnSuccess();

    if (!allMigrated) {
      handleBrokerPasswordResetSubmissionError();
      return;
    }

    handleBrokerPasswordResetSubmissionSuccess();
  } catch (error) {
    console.log(error);
    handleBrokerPasswordResetSubmissionError();
  }
};

const handleBrokerPasswordResetSubmissionError: () => void = () => {
  try {
    const loadingContainer: any = document.getElementById('loading-container');
    // const errorContainer: any = document.getElementById('error-container');

    loadingContainer.classList.remove('active');
    // errorContainer.classList.add('active');
  } catch (error) {
    console.log(error);
  }
};

const handleBrokerPasswordResetSubmissionSuccess: () => void = () => {
  try {
    // const targetForm: any = document.getElementById('new_BrokerPasswordReset');
    // targetForm.submit();
    window.location.href = './page-broker-login.html';
  } catch (error) {
    console.log(error);
  }
};
